@import "../../../variables";

.featured-categories {
    .feat-categories-pic {
        height: 124px;

        .feat-categories-img,
        .feat-tile-img {
            border-radius: $space-16;
            transition: all 0.5s;
        }

        .feat-tile-img {
            height: 116px;
            width: 116px;

            &:hover {
                height: 124px;
                width: 124px;
            }
        }
    }
}

[data-slides-to-display-lg="7"] {
    .feat-categories-img {
        width: 116px;

        &:hover {
            width: 124px;
        }
    }
}

